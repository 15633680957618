$include-fonts: false;
@import "../setup/settings";

.cc-courtesy-page{
    display: flex;
    flex-direction: column;
    height: 100%;

    .cc-courtesy-content{
        @include breakpoint (large up){
            margin: auto 0;
        }
    }

    .cc-courtesy-footer{
        margin-top: auto;
        background-color: $off-white;
        padding: rem-calc(40 20);
        .text-social{
            font-size: rem-calc(12);
        }
    }
}

.header-maintenance-page{
    height: rem-calc(69);
    display: flex;
    border-bottom: 1px solid $border-gray;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .img-logo{
        height: rem-calc(32);
    }
}
.courtesy-message-column{
    display: flex;
    flex-direction: column;
    justify-content: center;
    .courtesy-message-box{
        @include breakpoint (large up){
            width: 33vw;
            margin: auto;
        }
        .courtesy-message{
            font-size: rem-calc(32);
            margin-bottom: rem-calc(16);
            @include breakpoint (medium down){
                font-size: rem-calc(22);
                margin-top: rem-calc(25);
            }
        }
    }
    .hope-message-courtesy{
        margin-top: rem-calc(16);
        @include breakpoint (medium down){
            margin-bottom: rem-calc(50);
        }
    }
}

.courtesy-img {
    @include breakpoint (medium down){
        max-width: 100%;
        margin: 0 auto;
        margin-bottom: rem-calc(55);
    }
}

.icon-footer-follow-mobile{
    margin-right:rem-calc(30);
    &:last-child{
        margin-right: 0;
    }
}

.grid-container {
    padding-top: rem-calc(32);
    margin-bottom: rem-calc(120);
    @include breakpoint (large up) {
        padding-top: rem-calc(64);
        margin-bottom: rem-calc(160);
    }
    .cc-notFoundPage{
        background-color: $off-white;
        border-radius: rem-calc(4);
        padding: rem-calc(24 16);
        width: 100%;
        @include breakpoint (large up) {
            padding: rem-calc(40);
            width: 33.333vw;
        }
        &-content {
            display: flex;
            flex-direction: column;
            gap: rem-calc(16);
            &-pageTitleError,&-errorMessage,&-info {
                margin: 0;
                font-family: $body-font-family;
            }
            &-button {
                width: 100%;
                margin-top: rem-calc(8);
                margin-bottom: 0;
                @include breakpoint (large up) {
                    margin: 0;
                }
            }
        }
    }
}
.cc-csrfFail {
    display: flex;
    flex-direction: column;
    gap: rem-calc(16);
    &-title {
        margin: 0;
    }
    &-content {
            margin-bottom: rem-calc(16);
    }
}